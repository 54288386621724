import React from 'react'

function Coin() {
  return (
      <div>
          <h1>Hello coin</h1>
    </div>
  )
}

export default Coin